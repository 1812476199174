import datepicker from 'js-datepicker'
import Gumshoe from "gumshoejs"

var api;

function startmeeting(ele) {
  if(api) { api.dispose();}

  const domain = 'meet.jit.si';
  const options = {
    roomName: $(ele).data('meeting-id'),
    width: '100%',
    height: 500,
    parentNode: document.querySelector('#meet'),
    userInfo: {
      displayName: $(ele).data('display-name')
    }
  };
  api = new JitsiMeetExternalAPI(domain, options);
  api.executeCommand('subject', $(ele).data('subject'));
  setTimeout(function() {
    api.executeCommand('password', $(ele).data('meeting-pwd'));
  }, 3000);
}

function xlinks(parent, children) {
  $.each($(parent).find(children), function(index, value) {
		const tweetRegex = /https?:\/\/(?:www\.)?(?:twitter|x)\.com\/([a-zA-Z0-9_]+)\/status\/(\d+)/;
    const match = $(this).text().match(tweetRegex);

    if (match != null) {
      const tweetId = match[2];
      
      if (tweetId != '') {
        var $a = $(this);
        var tweetEmbed = `<blockquote class="twitter-tweet"><a href="https://twitter.com/i/status/${tweetId}"></a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`;
        
        var iframeContainer = $('<div>' + tweetEmbed + '</div>');
        $a.replaceWith(iframeContainer);
        $a.addClass('shadow');
      }
    }
  });
}

function embedLinkedInPost(parent, children) {
  $.each($(parent).find(children), function(index, value) {
    const url = $(this).text();

    const regex = /activity-(\d+)-/;
    const match = url.match(regex);
    const activityId = match ? match[1] : null;

    if(activityId != null) {
      const embedUrl = `https://www.linkedin.com/embed/feed/update/urn:li:activity:${activityId}/`;
      var $a = $(this);
      var iframe = `
              <iframe 
                src="${embedUrl}" 
                height="600" 
                width="100%" 
                frameborder="0" 
                allowfullscreen="" 
                title="LinkedIn Post">
              </iframe>
            `;
      var iframeContainer = $('<div class="embed-responsive embed-responsive-16by9">' + iframe + '</div>');
      $a.replaceWith(iframeContainer);
      $a.addClass('embed-responsive shadow');
    }
  });
}

function youtubelinks(parent, children) {
  $.each($(parent).find(children), function(index, value) {
		var match = $(this).text().match(/(?:https?:\/\/)(?:www\.)?(?:youtube|youtu)\.(?:be|com)\/[^\s]+/g);

    if(match != null) {
      var $a = $(this);
      for (var i = 0; i < match.length; i++) {
        var url = match[i];
        var video_id = '';

        if (video_id = url.match(/(\?|&)v=([^&#]+)/)) {
          video_id = video_id.pop();
        } else if (video_id = url.match(/(\.be\/)+([^\/]+)/)) {
          video_id = video_id.pop();
        } else if (video_id = url.match(/(\embed\/)+([^\/]+)/)) {
          video_id = video_id.pop().replace('?rel=0','');
        }

        if (video_id != '') {
          // var svg = '<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" class="video-player__btn homeVideoPlayButton" viewBox="0 0 48 48"  style=" fill:#000000;"><g><path class="path-fill"  d="M 43.199219 33.898438 C 42.800781 36 41.101563 37.601563 39 37.898438 C 35.699219 38.398438 30.199219 39 24 39 C 17.898438 39 12.398438 38.398438 9 37.898438 C 6.898438 37.601563 5.199219 36 4.800781 33.898438 C 4.398438 31.601563 4 28.199219 4 24 C 4 19.800781 4.398438 16.398438 4.800781 14.101563 C 5.199219 12 6.898438 10.398438 9 10.101563 C 12.300781 9.601563 17.800781 9 24 9 C 30.199219 9 35.601563 9.601563 39 10.101563 C 41.101563 10.398438 42.800781 12 43.199219 14.101563 C 43.601563 16.398438 44.101563 19.800781 44.101563 24 C 44 28.199219 43.601563 31.601563 43.199219 33.898438 Z "></path><path style=" fill:#FFFFFF;" d="M 20 31 L 20 17 L 32 24 Z "></path></g></svg>';
//           var div = '<div class="embed-responsive-item yt-thumbnail-overlay-image" style="background-image: url(https://i.ytimg.com/vi/' + video_id + '/sddefault.jpg)" ></div';
          // $a.get(0).innerHTML = div;
          // $a.append(svg);
          var iframe = '<iframe class="embed-responsive-item" src="https://www.youtube.com/embed/'
                      + video_id
                      + '" allow="autoplay" width="560" height="315" frameborder="0" allowfullscreen></iframe>';
          var iframeContainer = $('<div class="embed-responsive embed-responsive-16by9">' + iframe + '</div>');
          $a.replaceWith(iframeContainer);
          $a.addClass('embed-responsive embed-responsive-16by9 shadow yt-video');
          // $a.get(0).addEventListener("click", function(e) {
          //   e.preventDefault();
          //   var iframe = '<iframe class="embed-responsive-item" src="https://www.youtube.com/embed/'
          //               + video_id
          //               + '?&autoplay=1" allow="autoplay" width="560" height="315" frameborder="0" allowfullscreen></iframe>';
          //   var iframeContainer = $('<div class="embed-responsive embed-responsive-16by9">' + iframe + '</div>');
          //   $(e.target).parents('a').replaceWith(iframeContainer);
          // });
        }
      }
    }
  });
}

document.addEventListener("turbolinks:load", function() {
  $('.fb-share-button-echai').on('click', function(e) {
    e.preventDefault();
    FB.ui({
      method: 'share',
      href: $(this).data('url'),
    }, function(response){});
  });

  if ($('#day_navigation').length > 0){
    var spy = new Gumshoe('#day_navigation a', { offset: $('#day_navigation .navbar-nav').data('offset') });
    var spy1 = new Gumshoe('#main_navigation a', { offset: $('#main_navigation .navbar-nav').data('offset') });
  }

  var datepickerInputs = document.querySelectorAll('.datepicker-input');
  datepickerInputs.forEach(function(picker) {
    datepicker(picker, { startDay: 1 });
  });

  $('.timepicker-input').timepicker({
    step: 60,
    scrollDefault: "11:00am"
  });

  $('.timepicker-input').on('timeFormatError', function() {
    $('.timepicker-input').val('11:00am');
  });

  var ytlinks = youtubelinks('.event_notes', 'a');
  $.each(ytlinks, function(index, value) {});

  var xtlinks = xlinks('.event_notes', 'a');
  $.each(xtlinks, function(index, value) {});

  var linkedinlinks = embedLinkedInPost('.event_notes', 'a');
  // $.each(linkedinlinks, function(index, value) {});

  if($('#start_meeting').length > 0) {
    $('#start_meeting').on('click', function() {
      startmeeting($(this));
      $('#meet').addClass('pb-4');
    });
  }

  $('.close').on('click', function(e) {
    $('#embedModalContent').html(' ');
  })
})

